export interface LandingMenuModel {
  id: number;
  title: string;
  titleEn: string;
  descriptionEn: string;
  descriptionTr: string;
  url: string;
  logo: string;
  logoEn: string;
  enable: boolean;
}
