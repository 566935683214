import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceFormModel } from '../models/insurance-form.model';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {
  constructor(private _httpClient: HttpClient) {}

  createInsurance(body: InsuranceFormModel): Observable<any> {
    return this._httpClient.post<any>('/api/sigorta', body);
  }
}
