export interface GetUserDetailModel {
  message: string;
  messageEn: string;
  status: boolean;
  ad: string;
  cinsiyet: number;
  dogumTarihi: string;
  email: string;
  musteriId: number;
  plaka: string;
  soyad: string;
  telefon: string;
  userId: number;
  fcmToken: string;
}
