import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenModel } from '../models/hesap.model';

@Injectable({
  providedIn: 'root'
})
export class HesapService {
  constructor(private _httpClient: HttpClient) {}

  token(telNo: string, pass: string): Observable<TokenModel> {
    let body = {
      grant_type: 'password',
      username: telNo,
      password: pass
    };
    return this._httpClient.post<TokenModel>('/api/Hesap/Token', body);
  }

  async refreshToken(token: string): Promise<TokenModel> {
    return await this._httpClient.post<TokenModel>('/api/Hesap/RefreshToken', `"${token}"`).toPromise();
  }
}
