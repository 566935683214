import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IyzicoPaymentResponseModel,
  InvoicesModel,
  IyzicoPaymentModel,
  JobsModel,
  RequestSavedCreditCardsResponse,
  SavedCreditCardsReq,
  UserKeyRespModel
} from '../models/bob-vale.model';
@Injectable({
  providedIn: 'root'
})
export class CallCarService {}
