import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IyzicoPaymentResponseModel,
  InvoicesModel,
  IyzicoPaymentModel,
  JobsModel,
  RequestSavedCreditCardsResponse,
  SavedCreditCardsReq,
  UserKeyRespModel,
  AdvantageModelOther,
  AdvantageModel,
  AdvantageResult,
  CarSelectModel
} from '../models/bob-vale.model';

@Injectable({
  providedIn: 'root'
})
export class BobvaleService {
  constructor(private _httpClient: HttpClient) {}

  getContinuesJobs(telNo: string): Observable<JobsModel[]> {
    return this._httpClient.get<JobsModel[]>('/api/bobvale/GetContinuesJobs?telefon=' + telNo);
  }

  getCardUserKey(musteriId: number): Observable<UserKeyRespModel> {
    if (!musteriId) throw new Error('musteriId is required');
    return this._httpClient.get<UserKeyRespModel>('/api/bobvale/GetCardUserKey?musteriid=' + musteriId);
  }

  requestSavedCreditCards(body: SavedCreditCardsReq): Observable<RequestSavedCreditCardsResponse> {
    return this._httpClient.post<RequestSavedCreditCardsResponse>('/api/bobvale/RequestSavedCreditCards', body);
  }

  getInvoiceList(telNo: string): Observable<InvoicesModel[]> {
    if (!telNo) telNo = '0';
    return this._httpClient.get<InvoicesModel[]>('/api/bobvale/GetInvoiceList?telefon=' + telNo);
  }

  iyzicoPayment(body: IyzicoPaymentModel): Observable<IyzicoPaymentResponseModel> {
    return this._httpClient.post<IyzicoPaymentResponseModel>('/api/bobvale/IyzicoPayment', body);
  }

  checkPaymentResult(basketid: string): Observable<any> {
    return this._httpClient.get<any>('/api/bobvale/CheckPaymentResult?basketid=' + basketid);
  }

  getCallVehicleInfo(guid: string | null): Observable<CarSelectModel> {
    guid = guid ? guid : '';
    return this._httpClient.get<CarSelectModel>(`/api/vale/AracimiCagirBilgi/${guid}`);
  }

  getCallCarViaGuid(guid: string, teslimKapisi: number): Observable<CarSelectModel> {
    return this._httpClient.get<CarSelectModel>(`/api/vale/AracimiCagir/${guid}/${teslimKapisi}`);
  }

  checkAdvantageValueOthersNew(model: AdvantageModelOther): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValueOthersNew', model);
  }

  checkAdvantageValueNew(model: AdvantageModel): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValueNew', model);
  }

  checkAdvantageValue(model: AdvantageModel): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValue', model);
  }

  checkAdvantageValueOthers(model: AdvantageModelOther): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValueOthers', model);
  }
}
