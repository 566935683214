<ion-content>
  <ion-toolbar>
    <ion-button (click)="dismiss()" fill="clear">
      <ion-icon name="close-circle-outline" style="font-size: xx-large"></ion-icon>
    </ion-button>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
  <div class="cont">
    <div class="cont-info">
      <div [innerHTML]="message"></div>
      <div>
        <ion-icon *ngIf="messageType === 'ok'" class="icon color-green" name="checkmark-done-circle"></ion-icon>
        <ion-icon *ngIf="messageType === 'error'" class="icon color-red" name="remove-circle"></ion-icon>
        <ion-icon *ngIf="messageType === 'warning'" class="icon color-yellow" name="alert-circle"></ion-icon>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div style="padding: 10px">
    <ion-button expand="full" shape="round" (click)="dismiss()">{{ 'alert.done' | translate }}</ion-button>
  </div>
</ion-footer>
