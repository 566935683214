import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { AuthService } from '@bob/service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private authService: AuthService) {}
  async canLoad(): Promise<boolean> {
    const authStatus = await this.authService.isAuthenticated();
    return authStatus === 'true';
  }
}
