// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@bob/service';

@Injectable()
export class LoaderInterCeptService implements HttpInterceptor {
  private requestCounter = 0;
  private urlGorme: string[] = [];
  constructor(private loadService: LoaderService) {
    this.urlGorme.push('/api/bobvale/GetVersionCheck');
    this.urlGorme.push('/api/bobvale/CheckServiceStatus');
    this.urlGorme.push('/api/transfer/GetReservationPaymentStatus');
    // this.urlGorme.push('/api/transfer/getallendedjobs');
    // this.urlGorme.push('/api/transfer/getallcontinuesjobs');
    // this.urlGorme.push('/api/bobvale/RequestSavedCreditCards');
    // this.urlGorme.push('/api/bobvale/PacketSumCoupon?musteriid=');
    // this.urlGorme.push('/api/bobvale/PacketSum?musteriid=');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercepted request ... ');
    const yasakli = this.urlGorme.some(x => req.url.indexOf(x) !== -1);
    const httpVar = req.url.indexOf('http') !== -1;

    if (httpVar && !yasakli) {
      this.requestCounter++;
      // console.log(
      //   `%c + ${this.requestCounter}  %c (${req.url})`,
      //   'background: #444; color: #bada55',
      //   'background: #444; color: #995555'
      // );

      if (this.requestCounter === 1) {
        this.loadService.show();
      }
    }

    return next.handle(req).pipe(
      finalize(() => {
        const yasakli = this.urlGorme.some(x => req.url.indexOf(x) !== -1);
        const httpVar = req.url.indexOf('http') !== -1;

        if (httpVar && !yasakli) {
          this.requestCounter--;
          // console.log(
          //   `%c - ${this.requestCounter}  %c (${req.url})`,
          //   'background: #444; color: #ff5555',
          //   'background: #444; color: #995555'
          // );
        }
        if (this.requestCounter === 0 && this.loadService.isLoading) {
          this.loadService.hide();
        }
      })
    );
  }
}
