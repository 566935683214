import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PhoneTokenModel, TokenModel } from '../models/hesap.model';
import { GetUserDetailModel } from '../models/job-notification.model';

@Injectable({
  providedIn: 'root'
})
export class JobNotificationService {
  constructor(private _httpClient: HttpClient) {}

  deletePhoneToken(token: PhoneTokenModel): Observable<boolean> {
    return this._httpClient.post<boolean>('/api/bobvale/DeletePhoneToken', token);
  }

  sendPasswordNew(phone: string): Observable<boolean> {
    return this._httpClient.get<boolean>('/api/JobNotification/SendPassword?telefon=' + phone);
  }

  getUserDetail(): Promise<GetUserDetailModel> {
    return this._httpClient.get<GetUserDetailModel>('/api/JobNotification/LoginUser').toPromise();
  }

  savePhoneToken(token: PhoneTokenModel) {
    return this._httpClient.post<TokenModel>('/api/JobNotification/SavePhoneToken', token);
  }
}
