import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = false;

  constructor(public loadingController: LoadingController) {}

  // Show the loader for infinite time
  show(message?: string): void {
    this.isLoading = true;

    this.loadingController
      .create({
        // spinner: SpinnerType.bubbles,
        message: message,
        translucent: false,
        cssClass: 'custom-loading2',
        showBackdrop: false,
        backdropDismiss: false,
        duration: 0,
        animated: true
        // mode: 'md'
      })
      .then(res => {
        res.present().then(() => {
          if (!this.isLoading) {
            res.dismiss();
          }
        });
      });
  }

  // Hide the loader if already created otherwise return error
  hide(): void {
    if (!this.isLoading) return;
    this.isLoading = false;
    this.loadingController
      .dismiss()
      .then(() => {})
      .catch(() => {});
  }
}
enum SpinnerType {
  bubbles = 'bubbles',
  circles = 'circles',
  circular = 'circular',
  crescent = 'crescent',
  dots = 'dots',
  lines = 'lines',
  linesSmall = 'lines-small',
  linesSharp = 'lines-sharp',
  linesSharpSmall = 'lines-sharp-small'
}
