export class BaseResponse<T> {
  public code: number;
  public message: string;
  public messageEn: string;
  public status: boolean;
  public data: T;

  constructor(item: any) {
    this.code = item.code || 0;
    this.message = item.message || '';
    this.messageEn = item.messageEn || '';
    this.status = item.status || false;
    this.data = item.data || [];
  }
}
