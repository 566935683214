import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from '../models/base.model';
import * as model from '../models/transfer.model';

@Injectable({
  providedIn: 'root'
})
export class TransferService {
  constructor(private _httpClient: HttpClient) {}

  checkRewardProgramNew(
    model: model.CheckRewardProgramNewRequestModel
  ): Observable<BaseResponse<model.CheckRewardProgramNewResponseModel>> {
    return this._httpClient.post<BaseResponse<model.CheckRewardProgramNewResponseModel>>(
      '/api/transfer/CheckRewardProgramNew',
      model
    );
  }

  addReservation(body: model.AddReservationModel): Observable<BaseResponse<model.AddReservationDataRespModel>> {
    return this._httpClient.post<BaseResponse<model.AddReservationDataRespModel>>('/api/transfer/AddReservation', body);
  }

  getKopruOtoyolListesi(): Promise<BaseResponse<model.KopruOtoyolDataModel[]>> {
    return this._httpClient
      .get<BaseResponse<model.KopruOtoyolDataModel[]>>('/api/transfer/KopruOtoyolListesi')
      .toPromise();
  }
}
