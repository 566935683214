// import { AuthGuard } from './guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
// import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    // canLoad: [AutoLoginGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'smsvalidate',
    loadChildren: () => import('./auth/smsvalidate/smsvalidate.module').then(m => m.SmsvalidatePageModule)
  },
  {
    path: 'insurance/:type',
    loadChildren: () => import('./insurance/insurance.module').then(m => m.InsurancePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'transfer-basket',
    loadChildren: () =>
      import('./transfer/transfer-basket/transfer-basket.module').then(m => m.TransferBasketPageModule)
  },
  {
    path: 'transfer-add-info',
    loadChildren: () =>
      import('./transfer/transfer-add-info/transfer-add-info.module').then(m => m.TransferAddInfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'transfer-confirm',
    loadChildren: () =>
      import('./transfer/transfer-confirm/transfer-confirm.module').then(m => m.TransferConfirmPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'member/:val',
    loadChildren: () => import('./member/member.module').then(m => m.MemberPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-edit-payment-type',
    loadChildren: () =>
      import('./shared/pages/payments/add-edit-payment-type/add-edit-payment-type.module').then(
        m => m.AddEditPaymentTypePageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'list-invoice/:val',
    loadChildren: () =>
      import('./shared/pages/invoice/list-invoice/list-invoice.module').then(m => m.ListInvoicePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'list-payment-types/:val',
    loadChildren: () =>
      import('./shared/pages/payments/list-payment-types/list-payment-types.module').then(
        m => m.ListPaymentTypesPageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'list-my-cars',
    loadChildren: () => import('./mycars/list-my-cars/list-my-cars.module').then(m => m.ListMyCarsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'qr',
    loadChildren: () => import('./cuzdan/qr/qr.module').then(m => m.QrPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule)
  },
  {
    path: 'r/:id',
    loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectPageModule)
  },
  {
    path: 'kvkk',
    loadChildren: () => import('./static-content/static-content.module').then(m => m.StaticContentPageModule),
    data: {
      pageKey: 'kvkk'
    }
  },
  {
    path: 'gam',
    loadChildren: () => import('./static-content/static-content.module').then(m => m.StaticContentPageModule),
    data: {
      pageKey: 'data_usage'
    }
  },
  {
    path: 'teikpfiam',
    loadChildren: () => import('./static-content/static-content.module').then(m => m.StaticContentPageModule),
    data: {
      pageKey: 'user_approve'
    }
  },
  {
    path: 'hgs-aydinlatma-metni',
    loadChildren: () => import('./static-content/static-content.module').then(m => m.StaticContentPageModule),
    data: {
      pageKey: '31'
    }
  },
  {
    path: 'content/:pageKey',
    loadChildren: () => import('./static-content/static-content.module').then(m => m.StaticContentPageModule)
  },
  // {
  //   path: 'tabs',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  //   canLoad: [AuthGuard]
  // },
  // {
  //   path: "info-alert",
  //   loadChildren: () =>
  //     import("./shared/pages/info-alert/info-alert.module").then(
  //       (m) => m.InfoAlertPageModule
  //     ),
  // },
  // {
  //   path: "joker/:val",
  //   loadChildren: () =>
  //     import("./shared/pages/joker/joker.module").then(
  //       (m) => m.JokerPageModule
  //     ),
  // },
  // {
  //   path: 'smsvalidate',
  //   loadChildren: () => import('./smsvalidate/smsvalidate.module').then(m => m.SmsvalidatePageModule)
  // },
  // {
  //   path: 'registration',
  //   loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule)
  // },
  // {
  //   path: 'language-selec',
  //   loadChildren: () => import('./language-selec/language-selec.module').then(m => m.LanguageSelecPageModule)
  // },
  // {
  //   path: 'tabs',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  //   canLoad: [AuthGuard]
  // },
  // {
  //   path: 'insurance',
  //   loadChildren: () => import('./insurance/insurance.module').then(m => m.InsurancePageModule),
  // },
  // {
  //   path: 'google-address-search',
  //   loadChildren: () => import('./google-address-search/google-address-search.module').then(m => m.GoogleAddressSearchPageModule)
  // },
  // {
  //   path: 'add-payment',
  //   loadChildren: () => import('./initial/add-payment/add-payment.module').then(m => m.AddPaymentPageModule)
  // },
  // {
  //   path: 'add-invoice',
  //   loadChildren: () => import('./initial/add-invoice/add-invoice.module').then(m => m.AddInvoicePageModule)
  // },
  // {
  //   path: 'info-alert',
  //   loadChildren: () => import('./info-alert/info-alert.module').then(m => m.InfoAlertPageModule)
  // },
  // {
  //   path: 'no-internet',
  //   loadChildren: () => import('./no-internet/no-internet.module').then(m => m.NoInternetPageModule)
  // },
  // {
  //   path: 'faq-landing',
  //   loadChildren: () => import('./faqs/faq-landing/faq-landing.module').then(m => m.FaqLandingPageModule)
  // },
  // {
  //   path: 'faq-list',
  //   loadChildren: () => import('./faqs/faq-list/faq-list.module').then(m => m.FaqListPageModule)
  // },
  // {
  //   path: 'list-my-cars',
  //   loadChildren: () => import('./mycars/list-my-cars/list-my-cars.module').then(m => m.ListMyCarsPageModule)
  // },
  // {
  //   path: 'add-edit-car/:val',
  //   loadChildren: () => import('./mycars/add-edit-car/add-edit-car.module').then(m => m.AddEditCarPageModule)
  // },
  // {
  //   path: 'car-detail-joker/:val',
  //   loadChildren: () => import('./mycars/car-detail-joker/car-detail-joker.module').then(m => m.CarDetailJokerPageModule)
  // },
  // {
  //   path: 'list-payment-types/:val',
  //   loadChildren: () => import('./payments/list-payment-types/list-payment-types.module').then(m => m.ListPaymentTypesPageModule)
  // },
  // {
  //   path: 'add-edit-payment-type',
  //   loadChildren: () => import('./payments/add-edit-payment-type/add-edit-payment-type.module').then(m => m.AddEditPaymentTypePageModule)
  // },
  // {
  //   path: 'list-invoice/:val',
  //   loadChildren: () => import('./invoice/list-invoice/list-invoice.module').then(m => m.ListInvoicePageModule)
  // },
  // {
  //   path: 'edit-delete-invoice/:val',
  //   loadChildren: () => import('./invoice/edit-delete-invoice/edit-delete-invoice.module').then(m => m.EditDeleteInvoicePageModule)
  // },
  // {
  //   path: 'joker/:val',
  //   loadChildren: () => import('./joker/joker.module').then(m => m.JokerPageModule)
  // },
  // {
  //   path: 'list-promos',
  //   loadChildren: () => import('./promotions/list-promos/list-promos.module').then(m => m.ListPromosPageModule)
  // },
  // {
  //   path: 'antivirus-slot-selection',
  //   loadChildren: () => import('./antivirus/antivirus-slot-selection/antivirus-slot-selection.module').then(m => m.AntivirusSlotSelectionPageModule)
  // },
  // {
  //   path: 'faq',
  //   loadChildren: () => import('./faq/faq.module').then(m => m.FaqPageModule)
  // },
  // {
  //   path: 'car-learn-more',
  //   loadChildren: () => import('./transfer/car-learn-more/car-learn-more.module').then(m => m.CarLearnMorePageModule)
  // },
  // {
  //   path: 'list-search-item-joker',
  //   loadChildren: () => import('./list-search-item-joker/list-search-item-joker.module').then(m => m.ListSearchItemJokerPageModule)
  // },
  // {
  //   path: 'mesafeli-satis',
  //   loadChildren: () => import('./mesafeli-satis/mesafeli-satis.module').then( m => m.MesafeliSatisPageModule)
  // },
  // {
  //   path: 'transaction-detail/:val',
  //   loadChildren: () => import('./transaction-detail/transaction-detail.module').then(m => m.TransactionDetailPageModule)
  // },

  // {
  //   path: 'transfer-basket',
  //   loadChildren: () => import('./transfer/transfer-basket/transfer-basket.module').then(m => m.TransferBasketPageModule)
  // },
  // {
  //   path: 'transfer-add-info',
  //   loadChildren: () => import('./transfer/transfer-add-info/transfer-add-info.module').then(m => m.TransferAddInfoPageModule)
  // },
  // {
  //   path: 'transfer-confirm',
  //   loadChildren: () => import('./transfer/transfer-confirm/transfer-confirm.module').then(m => m.TransferConfirmPageModule)
  // },
  // {
  //   path: 'transactions',
  //   loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsPageModule)
  // },
  // {
  //   path: 'qr',
  //   loadChildren: () => import('./cuzdan/qr/qr.module').then(m => m.QrPageModule)
  // },
  // {
  //   path: 'add-amount',
  //   loadChildren: () => import('./cuzdan/add-amount/add-amount.module').then(m => m.AddAmountPageModule)
  // },
  // {
  //   path: 'alert',
  //   loadChildren: () => import('./alert/alert.module').then( m => m.AlertPageModule)
  // },
  // {
  //   path: 'driver',
  //   loadChildren: () => import('./transfer/driver/driver.module').then( m => m.DriverPageModule)
  // },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [BrowserModule, RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
