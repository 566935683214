export class InvoicesModel {
  public id: number;
  public adres: string;
  public faturaAlias: string;
  public faturaTipi: string;
  public il: string;
  public ilce: string;
  public isimSoyisim: string;
  public musteriId: number;
  public tarih: Date;
  public tcKimlikNoVergiNo: string;
  public vergiDairesi: string;
  public isDefault: boolean;
  public gorunur: boolean;

  constructor(item: any) {
    this.id = item.id || 0;
    this.adres = item.adres || '';
    this.faturaAlias = item.faturaAlias || '';
    this.faturaTipi = item.faturaTipi || '';
    this.il = item.il || '';
    this.ilce = item.ilce || '';
    this.isimSoyisim = item.isimSoyisim || '';
    this.musteriId = item.musteriId || 0;
    this.tarih = item.tarih || new Date();
    this.tcKimlikNoVergiNo = item.tcKimlikNoVergiNo || '';
    this.vergiDairesi = item.vergiDairesi || '';
    this.isDefault = item.isDefault || false;
    this.gorunur = item.gorunur || false;
  }
}

export class IyzicoPaymentModel {
  public cardno: string;
  public expiry: string;
  public cvc2: string;
  public amount: number;
  public discountamount: number;
  public actualamount: number;
  public gecelikTutar: number;
  public indirimTutariGecelik: number;
  public cardholdername: string;
  public plate: string;
  public rememberCard: boolean;
  public cardAlias: string;
  public cardToken: string;
  public cardUserKey: string;
  public invoiceId: number;
  public serviceId: string;
  public paymentType: string;
  public basketType: string;
  public doorId: number;
  public advantageId: number;
  public autoReload: boolean;
  public promotionCode: string;

  constructor(item?: any) {
    this.cardno = item?.cardno || undefined;
    this.expiry = item?.expiry || undefined;
    this.cvc2 = item?.cvc2 || undefined;
    this.amount = item?.amount || 0;
    this.discountamount = item?.discountamount || undefined;
    this.actualamount = item?.actualamount || 0;
    this.gecelikTutar = item?.gecelikTutar || undefined;
    this.indirimTutariGecelik = item?.indirimTutariGecelik || undefined;
    this.cardholdername = item?.cardholdername || undefined;
    this.plate = item?.plate || undefined;
    this.rememberCard = item?.rememberCard || undefined;
    this.cardAlias = item?.cardAlias || undefined;
    this.cardToken = item?.cardToken || undefined;
    this.cardUserKey = item?.cardUserKey || undefined;
    this.invoiceId = item?.invoiceId || undefined;
    this.serviceId = item?.serviceId || undefined;
    this.paymentType = item?.paymentType || undefined;
    this.basketType = item?.basketType || undefined;
    this.doorId = item?.doorId || undefined;
    this.advantageId = item?.advantageId || undefined;
    this.autoReload = item?.autoReload || undefined;
    this.promotionCode = item?.promotionCode || undefined;
  }
}

export class IyzicoPaymentResponseModel {
  public html: string;
  public basketId: string;
  public endurl: string;
  public status: boolean;
  public couponUsedAmount: number;

  constructor(item?: any) {
    this.basketId = item?.basketId || undefined;
    this.couponUsedAmount = item?.couponUsedAmount || undefined;
    this.endurl = item?.endurl || undefined;
    this.html = item?.html || undefined;
    this.status = item?.status || undefined;
  }
}

export class SavedCreditCardsReq {
  public cardUserKey: string;

  constructor(item: any) {
    this.cardUserKey = item.cardUserKey || undefined;
  }
}

export class RequestSavedCreditCardsResponse {
  public cards: CardModel[];
  public isWalletDefault: boolean;

  constructor(item?: any) {
    this.cards = item.cards || [];
    this.isWalletDefault = item.isWalletDefault || undefined;
  }
}

export class CardModel {
  public cardalias: string;
  public cardtoken: string;
  public cardType: string;
  public binnumber: string;
  public background: string;
  public backgroundColor: string;
  public bank: string;
  public bankLogo: string;
  public carduserkey: string;
  public isDefault: boolean;
  public balance: number;

  constructor(item?: any) {
    this.cardalias = item?.cardalias || '';
    this.cardtoken = item?.cardtoken || '';
    this.cardType = item?.cardType || '';
    this.binnumber = item?.binnumber || '';
    this.background = item?.background || '';
    this.backgroundColor = item?.backgroundColor || '';
    this.bank = item?.bank || '';
    this.bankLogo = item?.bankLogo || '';
    this.carduserkey = item?.carduserkey || '';
    this.isDefault = item?.isDefault || false;
    this.balance = item?.balance || 0;
  }
}

export class JobsModel {
  public ad: string;
  public aracCagirmaTarihi: Date;
  public anketDurum: string;
  public aracGetirSure: string;
  public aracLogo: string;
  public aracMarka: string;
  public aracModel: string;
  public aracRenk: string;
  public aracRenk_en: string;
  public aracTeslimKapi: string;
  public aracTip: string;
  public aracTip_en: string;
  public avantajlar: string;
  public currency: string;
  public driverSurveyPoint: number;
  public fatura: JobsFaturaModel;
  public fromAddress: string;
  public gecelikTutar: number;
  public hizmetId: string;
  public hizmetIstasyonlar: HizmetIstasyonModel[];
  public hizmetRezervasyonId: number;
  public hizmetTipId: number;
  public hizmetTipiAciklama: string;
  public hizmetYer: string;
  public hizmetYerId: number;
  public hizmetDurumId: number;
  public indirimTutari: string;
  public isCancel: boolean;
  public kullanilanAvantaj: string;
  public kullanilanAvantajEn: string;
  public musteriId: number;
  public odemeToplam: number;
  public odenenTutar: number;
  public passengerCount: number;
  public plaka: string;
  public pnr: string;
  public recordStatus: string;
  public recordStatusEn: string;
  public reservationDate: string;
  public reservationId: number;
  public reservationNo: string;
  public serviceName: string;
  public soyad: string;
  public tarih: string;
  public telefon: string;
  public toAddress: string;
  public totalCost: number;
  public transferHizmetTipId: number;
  public tutar: number;
  public yikamaTutari: number;
  public showRating: boolean;
  public cikis_tarihi_gerceklesen: string;
  public cikis_tarihi_musteri_bildirimi: string;
  public giris_tarihi_gerceklesen: string;
  public giris_tarihi_musteri_bildirimi: string;
  public teslimIstasyon: HizmetIstasyonModel;
  public odemeDurumu: boolean;
  public odenecekTutar: number;
  public selectedPromo: any;
  public payablePrice: number;
  public price: number;
  public promo: PromoJobModel;
  public selectedPayment: CardModel;

  constructor(item?: any) {
    this.ad = item.ad || '';
    this.aracCagirmaTarihi = item.aracCagirmaTarihi || '';
    this.anketDurum = item?.anketDurum || undefined;
    this.aracGetirSure = item?.aracGetirSure || undefined;
    this.aracLogo = item?.aracLogo || undefined;
    this.aracMarka = item?.aracMarka || undefined;
    this.aracModel = item?.aracModel || undefined;
    this.aracRenk = item?.aracRenk || undefined;
    this.aracRenk_en = item?.aracRenk_en || undefined;
    this.aracTeslimKapi = item?.aracTeslimKapi || undefined;
    this.aracTip = item?.aracTip || undefined;
    this.aracTip_en = item?.aracTip_en || undefined;
    this.avantajlar = item?.avantajlar || undefined;
    this.currency = item?.currency || undefined;
    this.driverSurveyPoint = item?.driverSurveyPoint || undefined;
    this.fatura = item?.fatura || undefined;
    this.fromAddress = item?.fromAddress || undefined;
    this.gecelikTutar = item?.gecelikTutar || undefined;
    this.hizmetId = item?.hizmetId || undefined;
    this.hizmetIstasyonlar = item?.hizmetIstasyonlar || [];
    this.hizmetRezervasyonId = item?.hizmetRezervasyonId || undefined;
    this.hizmetTipId = item?.hizmetTipId || undefined;
    this.hizmetTipiAciklama = item?.hizmetTipiAciklama || undefined;
    this.hizmetYer = item?.hizmetYer || undefined;
    this.hizmetYerId = item?.hizmetYerId || undefined;
    this.hizmetDurumId = item?.hizmetDurumId || undefined;
    this.indirimTutari = item?.indirimTutari || undefined;
    this.isCancel = item?.isCancel || undefined;
    this.kullanilanAvantaj = item?.kullanilanAvantaj || undefined;
    this.kullanilanAvantajEn = item?.kullanilanAvantajEn || undefined;
    this.musteriId = item?.musteriId || undefined;
    this.odemeToplam = item?.odemeToplam || undefined;
    this.odenenTutar = item?.odenenTutar || undefined;
    this.passengerCount = item?.passengerCount || undefined;
    this.plaka = item?.plaka || undefined;
    this.pnr = item?.pnr || undefined;
    this.recordStatus = item?.recordStatus || undefined;
    this.recordStatusEn = item?.recordStatusEn || undefined;
    this.reservationDate = item?.reservationDate || undefined;
    this.reservationId = item?.reservationId || undefined;
    this.reservationNo = item?.reservationNo || undefined;
    this.serviceName = item?.serviceName || undefined;
    this.soyad = item?.soyad || undefined;
    this.tarih = item?.tarih || undefined;
    this.telefon = item?.telefon || undefined;
    this.toAddress = item?.toAddress || undefined;
    this.totalCost = item?.totalCost || undefined;
    this.transferHizmetTipId = item?.transferHizmetTipId || undefined;
    this.tutar = item?.tutar || undefined;
    this.yikamaTutari = item?.yikamaTutari || undefined;
    this.showRating = item?.showRating || undefined;
    this.cikis_tarihi_gerceklesen = item?.cikis_tarihi_gerceklesen || undefined;
    this.cikis_tarihi_musteri_bildirimi = item?.cikis_tarihi_musteri_bildirimi || undefined;
    this.giris_tarihi_gerceklesen = item?.giris_tarihi_gerceklesen || undefined;
    this.giris_tarihi_musteri_bildirimi = item?.giris_tarihi_musteri_bildirimi || undefined;
    this.teslimIstasyon = item?.teslimIstasyon || new HizmetIstasyonModel({});
    this.odemeDurumu = item?.odemeDurumu || false;
    this.odenecekTutar = item?.odenecekTutar || undefined;
    this.selectedPromo = item?.selectedPromo || undefined;
    this.payablePrice = item?.payablePrice || undefined;
    this.price = item?.price || undefined;
    this.promo = item?.promo || new PromoJobModel({});
    this.selectedPayment = item?.selectedPayment || new CardModel({});
  }
}

export class JobsFaturaModel {
  public faturaTipi: string;
  public tcKimlikNoVergiNo: string;
  public adres: string;
  public ilce: string;
  public il: string;

  constructor(item?: any) {
    this.faturaTipi = item?.faturaTipi || undefined;
    this.tcKimlikNoVergiNo = item?.tcKimlikNoVergiNo || undefined;
    this.adres = item?.adres || undefined;
    this.ilce = item?.ilce || undefined;
    this.il = item?.il || undefined;
  }
}
export class HizmetIstasyonModel {
  public ad: string;
  public id: number;

  constructor(item: any) {
    this.ad = item.ad || '';
    this.id = item.id || 0;
  }
}
export class PromoJobModel {
  public title: string;
  public id: number;
  public code: string;
  public transactionId: string;
  public ad: string;
  public ad_en: string;
  public promoCode: string;

  constructor(item: any) {
    this.title = item.title || '';
    this.id = item.id || 0;
    this.code = item.code || '';
    this.transactionId = item.transactionId || '';
    this.ad = item.ad || '';
    this.ad_en = item.ad_en || '';
    this.promoCode = item.promoCode || '';
  }
}

export interface UserKeyRespModel {
  key: string;
}

export class AdvantageModelOther {
  public telefon: string | undefined;
  public prm: string | undefined;
  public amount: number | undefined;
  public musteriId: number | undefined;
  public hizmetId: number | undefined;
  public hizmetYerId: number | undefined;
  public advantageId: number | undefined;
  public kartId: number | undefined;
}

export class AdvantageModel {
  public telefon: string | undefined;
  public prm: string | undefined;
  public hizmetId: string | undefined;
  public advantageId: number | undefined;
  public kartId: number | undefined;
}

export class AdvantageResult {
  public status: boolean | undefined;
  public amount: number | undefined;
  public message: string | undefined;
  public message_en: string | undefined;
}
export class CarSelectModel {
  public marka: string;
  public model: string;
  public renk: string;
  public logo: string;
  public hizmetYerId: number;
  public hizmetYerAd: string;
  public plaka: string;
  public istasyonAdi: string;
  public aracTipi: string;
  public istasyonSecenekleri: HizmetIstasyonModel[];
  public error: string;
  public sure: string;

  constructor(item: any) {
    this.marka = item.marka || '';
    this.model = item.model || '';
    this.renk = item.renk || '';
    this.logo = item.logo || '';
    this.hizmetYerId = item.hizmetYerId || null;
    this.hizmetYerAd = item.hizmetYerAd || null;
    this.plaka = item.plaka || '';
    this.istasyonAdi = item.istasyonAdi || '';
    this.logo = item.logo || '';
    this.aracTipi = item.aracTipi || '';
    this.istasyonSecenekleri = item.istasyonSecenekleri || [];
    this.error = item.error || '';
    this.sure = item.aracGetirSure || '';
  }
}
