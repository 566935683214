export class InsuranceFormModel {
  public isPlate: boolean;
  public plate: string;
  public tckn: string;
  public email: string;
  public phone: string;
  public birthday: Date;
  public type: number | null;

  constructor(item?: any) {
    this.isPlate = item?.isPlate || false;
    this.plate = item?.plate || '';
    this.tckn = item?.tckn || '';
    this.email = item?.email || '';
    this.phone = item?.phone || '';
    this.birthday = item?.birthday || new Date();
    this.type = item?.type || 0;
  }
}
