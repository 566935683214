<ion-app>
  <div *ngIf="showSplash || !googleMapFlag" class="splash">
    <div class="spinner"></div>
    <div class="spinner_harf"><img src="../assets/images/logo/logo_yeni.svg" alt="" /></div>
  </div>
  <div *ngIf="!showSplash && googleMapFlag" class="splash">
    <ion-router-outlet *ngIf="googleMapFlag"></ion-router-outlet>
  </div>
  <!-- <div *ngIf="googleMapError">
    //ToDO
  </div> -->
</ion-app>
