import { CardModel, InvoicesModel } from '@bob/api';

export class CustomerModel {
  public ad: string;
  public cinsiyet: number;
  public dogumTarihi: Date;
  public email: string;
  public musteriId: number;
  public plaka: string;
  public soyad: string;
  public status: boolean;
  public telefon: string;
  public userId: number;
  public password: string;
  public ccards: CardModel[];
  public defaultCcard: CardModel;
  public cardUserKey: string;
  public walletBalance: number;
  public qrCode: string;
  public invoices: InvoicesModel[];
  public defaultInvoice: InvoicesModel | undefined;
  public isWalletDefault: boolean;
  public noLoginInvoiceSaved: boolean;
  public profilePic: string;
  public fcmToken: any;
  constructor(item?: any) {
    this.ad = item?.ad || undefined;
    this.cinsiyet = item?.cinsiyet || 0;
    this.dogumTarihi = item?.dogumTarihi || new Date().toISOString();
    this.email = item?.email || undefined;
    this.musteriId = item?.musteriId || undefined;
    this.plaka = item?.plaka || undefined;
    this.soyad = item?.soyad || undefined;
    this.status = item?.status || false;
    this.telefon = item?.telefon || undefined;
    this.userId = item?.userId || undefined;
    this.password = item?.password || undefined;
    this.ccards = item?.ccards || [];
    this.defaultCcard = item?.defaultCcard || new CardModel({});
    this.cardUserKey = item?.cardUserKey || undefined;
    this.walletBalance = item?.walletBalance || undefined;
    this.qrCode = item?.qrCode || undefined;
    this.defaultInvoice = item?.defaultInvoice || undefined;
    this.invoices = item?.invoices || [];
    this.noLoginInvoiceSaved = item?.noLoginInvoiceSaved || false;
    this.isWalletDefault = item?.isWalletDefault || undefined;
    this.profilePic = item?.profilePic || undefined;
    this.fcmToken = item?.fcmToken || undefined;
  }
}

export class DocumentApproveModel{
  public gam:string;
  public rp:string;
  constructor( item?: any) {
    this.gam = item?.gam || null;
    this.rp = item?.rp || null;
  }
}
