/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { AlertController, AnimationController, ModalController, ToastController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
// import { InfoAlertPage } from '../shared/pages/info-alert/info-alert.page';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public ok = 'ok';
  public error = 'error';
  public warning = 'warning';

  constructor(
    private toastController: ToastController,
    public alertModalController: ModalController,
    public animationCtrl: AnimationController,
    public alertController: AlertController // private toastr: ToastrService
  ) {}

  async presentToast(message: any) {
    // this.toastr.success(message, 'Toastr fun!');
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
      position: 'top',
      color: 'secondary'
    });
    toast.present();
  }

  async presentOkAlert(title: string, subtitle: string, msg: string) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: subtitle,
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  async presentBobCustomAlertWeb(message: any, page: any) {
    const modal = await this.alertModalController.create({
      component: page,
      componentProps: message
    });
    await modal.present();
  }

  async presentBobCustomAlertMobile(message: any, page: any) {
    const enterAnimation = (baseEl: any) => {
      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(baseEl.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(baseEl.querySelector('.modal-wrapper')!)
        .fromTo('transform', 'translateX(500px)', 'translateX(0px)');

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(250)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const modal = await this.alertModalController.create({
      component: page,
      cssClass: 'info-alert-modal',
      componentProps: message
      // enterAnimation,
      // leaveAnimation
    });
    await modal.present();
  }
}
