export class StaticContentType {
  public title: string;
  public page: string;

  constructor(item: any) {
    this.title = item.title || '';
    this.page = item.page || '';
  }
}
export class StaticContentDetails {
  public stCont: number;
  public lang: string;

  constructor(item: any) {
    this.stCont = item.stCont || 0;
    this.lang = item.lang || '';
  }
}

export class MesafeliSozlesmeDataModel {
  public adsoyad: string;
  public adres: string;
  public telefon: string;
  public email: string;
  public faturaadres: string;
  public hizmetadi: string;
  public hizmetbedeli: string;
  public hizmetkdv: string;
  public indirimtutari: string;
  public toplamfiyat: string;

  constructor(item?: any) {
    this.adsoyad = item?.adsoyad || undefined;
    this.adres = item?.adres || undefined;
    this.telefon = item?.telefon || undefined;
    this.email = item?.email || undefined;
    this.faturaadres = item?.faturaadres || undefined;
    this.hizmetadi = item?.hizmetadi || undefined;
    this.hizmetbedeli = item?.hizmetbedeli || undefined;
    this.hizmetkdv = item?.hizmetkdv || undefined;
    this.indirimtutari = item?.indirimtutari || undefined;
    this.toplamfiyat = item?.toplamfiyat || undefined;
  }
}
