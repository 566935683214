import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

const LNG_KEY = 'SELECTED_LANGUAGE';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public selected = '';
  private selectedLanguage: BehaviorSubject<string> = new BehaviorSubject('tr');
  public selectedLanguage$: Observable<string> = this.selectedLanguage.asObservable();

  constructor(
    private translate: TranslateService,
    private config: PrimeNGConfig,
    private storageService: StorageService
  ) {
    console.log('LanguageService');
  }

  setInitialAppLanguage() {
    this.selected = this.translate.getBrowserLang() === 'tr' ? 'tr' : 'en';
    this.setLanguage(this.selected);
  }

  getLanguages() {
    return [
      { text: 'ENGLISH', value: 'en', img: 'assets/images/en_bayrak.png' },
      { text: 'TÜRKÇE', value: 'tr', img: 'assets/images/tr_bayrak.png' }
    ];
  }

  setLanguage(lng: string) {
    this.translate.use(lng);
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.selected = lng;
    this.storageService.set({ key: LNG_KEY, value: JSON.stringify(lng) });
    this.selectedLanguage.next(lng);
    console.log('lng', lng);
  }
}
