import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { LanguageService } from '@bob/service';
import { Platform } from '@ionic/angular';
import { timer } from 'rxjs';

@Component({
  selector: 'app-bob-frontend-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public showSplash = true;
  private flag = true;
  public googleMapFlag = false;
  public googleMapError = false;

  constructor(private platform: Platform, private languageService: LanguageService, private httpClient: HttpClient) {
    // this.storage.create();
    this.initializeApp();
    this.getGoogleJs();
  }

  async getGoogleJs() {
    this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCyQWxs2cQUciOJ27VYqgyTxdCQ1PWSkWQ',
        'callback'
      )
      .subscribe({
        next: () => {
          this.googleMapFlag = true;
        },
        error: () => {
          this.googleMapFlag = true;
          this.getGoogleJs();
          // this.googleMapError = false;
          // location.reload();
        }
      });
  }
  async initializeApp() {
    this.flag = false;
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault(); // Sorulmalı
      this.languageService.setInitialAppLanguage();
      timer(700).subscribe(() => (this.showSplash = false));
    });
  }
}
