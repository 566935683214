export class JwtModel {
  public nameid: string | undefined;
  public nameidentifier: string | undefined;
  public birey: string | undefined;
  public name: string | undefined;
  public gam: string | undefined;
  public rp: string | undefined;
  public role: string[] | undefined;
  public group: string[] | undefined;
  public eskiMusteriId: string | undefined;
  public exp: number | undefined;
  public iss: string | undefined;
  public aud: string | undefined;
}

export class TokenModel {
  public access_token: string | undefined;
  public token_type: string | undefined;
  public expires_in: number | undefined;
  public scope: string | undefined;
  public refreshToken: string | undefined;
}

export class PhoneTokenModel {
  public token: string | undefined;
  public isPhone: boolean | undefined;
  public tipi: PhoneTokenType | undefined;
}

export enum PhoneTokenType {
  web = 0,
  ios = 1,
  android = 2
}
